/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: Muller;
  src: url(./font/Muller\ ExtraBold\ DEMO.otf);
}

.stakedInput {
  border: 2px solid;
  margin-top: 20px;
  border-radius: 20px;
  font-size: 20px;
  padding: 10px 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accord .accordion-item {
  border: 0px solid;
}

.currencyInput {
  border: 1px solid transparent !important;
}

.timer {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
}

.timer div {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

* {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.items a {
  text-decoration: none;
}

.items a:hover {
  border-radius: 15px;
}

.activeNav {
  border-radius: 15px;
}

.items a:focus {
  border-radius: 15px;
}

.accord .accordion-button:focus {
  outline: 0 !important;
}

.accord .accordion-button:not(.collapsed) {
  border-bottom: 0;
  border-radius: 40px 40px 0 0;
}

.accord .accordion-body:not(.collapsed) {
  border-top: 0;
  border-radius: 0 0 40px 40px;
}

.accord .accordion-button::after {
  display: none;
}

.search_bar input {
  border: none;
}

.search_bar input:focus {
  border: none;
  outline: 0;
}

.btn_mode {
  border: none;
}

.btn_m_s {
  border-radius: 10px;
}

.btn_radio_grp {
  width: 25%;
  border-radius: 15px;
}

.btn_radio {
  width: 50%;
  height: fit-content;
  border-radius: 15px;
}

.btn_gray_white {
  width: 23%;
  border-radius: 10px;
}

.btn_gray_white2 {
  width: 100%;
  border-radius: 10px;
}

.btn_orange2 {
  width: 100%;
  border-radius: 10px;
}

.btn_orange2:hover {
  width: 100%;
  border-radius: 10px;
}

.btn_orange {
  width: 45%;
  border-radius: 10px;
}

.btn_orange:hover {
  border-radius: 10px;
}

.header .icn_btn {
  border-radius: 10px;
}

.size_icn {
  font-size: 20px;
}

.icn_btn {
  border-radius: 10px;
}

.searchBar {
  width: 50%;
  min-width: 40%;
}

.no-rap {
  white-space: nowrap;
}

.font1 {
  font-size: 13px;
}

.font2 {
  font-size: 18px;
}

.offcanvasCloseBtn {
  border: none;
}

.borderR-5 {
  border-radius: 5px;
}

.text-right {
  text-align: right;
}

.accord .accordion-button:not(.collapsed) .header_btns {
  visibility: hidden !important;
}

.accord .accordion-button:not(.collapsed) .angle_down {
  visibility: hidden !important;
}

.accord .accordion-button:not(.collapsed) .angle_up {
  visibility: hidden !important;
}

.burnSwap {
  border-radius: 15px;
}
.burnSwap.liquify{
  margin: auto;
  display: grid;
  place-content: center;
}

.currencyInput {
  /* margin: 10px !important; */
  padding: 10px;
  border-radius: 20px;
}

.numberContainer {
  text-align: left;
}

.spinnerContainer {
  height: 55px;
  line-height: 55px;
}

.currencyInputField {
  font-size: 20px;
  display: inline-block;
  width: 100%;
  border: none;
}

.currencyInputField:focus-visible {
  border: 0px !important;
  outline: none;
}

.tokenContainer {
  text-align: right;
}

.tokenName {
  font-size: 28px;
  display: block;
}

.balanceContainer {
  display: block;
}

.balanceAmount {
  width: 100%;
  font-size: 16px;
  display: inline;
}

.statsCard {
  padding: 20px 15px;
}

.docsIcon {
  color: rgba(208, 51, 192, 1);
}

.dropdown-menu.show {
  top: 15px !important;
  border-radius: 15px;
}

.currencyDropdown ul li a {
  font-size: 20px !important;
}

.wlltmdl .modal-dialog {
  max-width: 425px;
}

.wlltmdl .modal-dialog-centered-custom {
  min-height: calc(68% - 3.5rem);
}

.wlltmdl .modal-content {
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 32px !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px,
    rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
}

.wlltmdl .modal-title {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.334;
  color: rgba(0, 0, 0, 0.87);
}

.wlltmdl .close {
  font-size: 32px;
  font-weight: 400;
  margin-right: -10px;
  background-color: transparent;
  border: none;
}

.mdl_c_btn {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  color: #000 !important;
  font-size: 16px;
  padding: 7px 20px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  text-transform: inherit !important;
  background-color: #f4f6f8 !important;
}

.mdl_c_btn:hover {
  background-color: #dfe3e8 !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1800px;
  }
}

@media (max-width: 576px) {
  .addressText1{
    font-size: 12px;
  }
  .header .icn_btn {
    margin-left: 10px !important;
  }

  .logoSize {
    width: 100%;
  }

  .profileImg {
    max-height: 3em;
  }

  h6 {
    font-size: 14px;
  }

  span {
    font-size: 13px;
  }

  .accord .accordion-button {
    display: flex;
    flex-wrap: wrap;
  }

  .btn_radio_grp {
    width: 65%;
  }

  .btn_gray_white {
    margin-left: 2%;
    font-size: small;
    height: max-content;
    width: 25%;
  }

  .btn_orange {
    margin-left: 2%;
    font-size: small;
    height: max-content;
    width: 60%;
  }

  .size_icn {
    font-size: 12px;
  }

  .accord .accordion-button {
    border-bottom: 0;
    border-radius: 40px !important;
  }

  .accord .accordion-button:not(.collapsed) {
    border-bottom: 0;
    border-radius: 40px 40px 0 0 !important;
  }

  .sm_font {
    font-size: small;
  }

  .header_btns {
    width: 85%;
  }

  .accountAddress {
    font-size: 13px;
  }

  .link1 {
    width: 100%;
    text-align: center;
  }

  .link2 {
    width: 100%;
  }

  .link3 {
    width: 100%;
    text-align: center;
  }

  .linkP {
    font-size: 17px;
    text-align: left !important;
    width: 100%;
  }

  .vediotag {
    width: 100% !important;
    height: 60vh;
    object-fit: cover;
    margin: 0px;
    overflow: hidden !important;
  }

  .patnersLogo {
    width: 75% !important;
  }
  .patnersLogo.dexScreener {
    width: 25% !important;
  }

  .patnersLogo2 {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .header .icn_btn {
    margin-left: 50px !important;
  }
  .addressText1{
    font-size: 16px;
  }

  .logoSize {
    width: 100%;
  }

  .profileImg {
    max-height: 4em;
  }

  .btn_radio_grp {
    width: 40%;
  }

  .accord .accordion-button {
    display: flex;
    flex-wrap: wrap;
  }

  .link1 {
    width: 25%;
    text-align: left;
  }

  .link2 {
    width: 75%;
  }

  .link3 {
    width: 25%;
    text-align: right;
  }

  .linkP {
    font-size: 17px;
    width: 80%;
  }

  .vediotag {
    width: 100% !important;
    height: 100vh;
    object-fit: cover;
    margin: 0px;
    overflow: hidden !important;
  }

  .patnersLogo {
    width: 75% !important;
  }
  .patnersLogo.dexScreener {
    width: 30% !important;
  }

  .patnersLogo2 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .addressText1{
    font-size: 18px;
  }
  .header .icn_btn {
    margin-left: 100px !important;
  }

  .logoSize {
    width: 75%;
  }

  .profileImg {
    max-height: 5em;
  }

  #exampleModal3 .modal-dialog {
    margin-left: 40% !important;
  }

  .link1 {
    width: 20%;
    text-align: left;
  }

  .link2 {
    width: 80%;
  }

  .link3 {
    width: 20%;
    text-align: right;
  }

  .linkP {
    font-size: 17px;
    width: 60%;
  }

  .vediotag {
    width: 100% !important;
    height: 100vh;
    object-fit: cover;
    margin: 0px;
    overflow: hidden !important;
  }

  .patnersLogo {
    width: 50% !important;
  }

  .patnersLogo.dexScreener {
    width: 20% !important;
  }

  .patnersLogo2 {
    width: 75% !important;
  }
}

.modal-header {
  border-width: 0px !important;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none !important;
}

:root {
  --angle: 45deg;
  --opacity: 0.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.rainbow {
  margin: auto;

  display: grid;
  place-content: center;
  text-align: center;


  --border-size: 0.3rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(from var(--angle),
      #d53e33 0deg 90deg,
      #d53e33 90deg 180deg,
      rgb(111, 26, 5) 180deg 270deg,
      rgb(111, 26, 5) 270deg 360deg) 1 stretch;
}

/** show a warning in browers that don't support it **/
.warning {
  margin: 2em;
  padding: 1em;
  border: 1px solid #ccc;
}

.warning p {
  margin: 0;
  padding: 0;
  text-align: center;
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .rainbow {
    animation: rotate 4s linear infinite;
  }

  /* Hide the warning */
  .warning {
    display: none;
  }
}

.loader {
  border: 10px solid #fdfdfd;
  border-radius: 50%;
  border-top: 10px solid #008c9e;
  width: 200px;
  height: 200px;
  animation: spin 2s linear infinite;
}

.loader_outer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  background: #0000008c;
}

.loader_outer p {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  margin-top: 30px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.iframeSetting {
  height: 100vh !important;
}

.text-left {
  text-align: left !important;
}

.video-react {
  padding: 0px !important;
  margin: 0px !important;
}

.LinkOuter {
  position: fixed;
  top: 35px;
  /* right: 50px; */
}

.link {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.docsColor {
  color: #008c9e;
}

.arrowImg {
  transform: scaleX(-1);
}

.modalCustom {
  position: fixed;
  margin: auto;
  top: 0;
  background-color: rgba(204, 185, 185, 0.55);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCustomchild {
  width: 50%;
  margin: auto;
  border-radius: 12px;
}

.agreementTitle {
  text-align: center !important;
  display: block;
}

.font-C {
  font-size: 23px;
  color: #fff !important;
}

#cf {
  position: relative;
}

#cf img.top {
  position: absolute;
  left: 0;
  animation: cf3FadeInOut 5s linear infinite;
}

@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btcContent,
.btcContent1 {
  color: #fff;
}

.btcContent h1,
.btcContent1 h1,
.btcContent1 h2 {
  font-family: 'Muller', sans-serif !important;
  font-weight: 800;
}

.btcContent b,
.btcContent1 b {
  font-family: 'Muller', sans-serif !important;
}

@media (max-width: 576px) {
  .btcContent h1 {
    font-size: 45px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .btcContent p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .btcContent1 h1 {
    font-size: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 800;
  }

  .btcContent1 h2 {
    font-size: 25px;
    margin-bottom: 0px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .btcContent1 p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .launchBtn {
    font-size: 12px;
    padding: 14px 14px;
  }

  header.header .img {
    width: 21px;
    font-size: 21px;
    margin-right: 11px !important;
  }

  .btcContent1 {
    height: 686px;
  }

  .dxoAnimatedBg {
    background-position: 100% 3%;
    background-size: 160% !important;
  }

  .btcContent1 {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .launchBtn1.launchBtn {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .btcContent h1 {
    font-size: 45px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .btcContent p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .btcContent1 h1 {
    font-size: 48px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 800;
  }

  .btcContent1 h2 {
    font-size: 30px;
    margin-bottom: 0px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .btcContent1 p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .launchBtn {
    font-size: 18px;
    padding: 15px 40px;
  }

  header.header .img {
    width: 30px;
    font-size: 30px;
  }

  .btcContent1 {
    height: 500px;
  }

  .dxoAnimatedBg {
    background-position: center;
  }

  .btcContent1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .btcContent h1 {
    font-size: 45px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .btcContent p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .btcContent1 h1 {
    font-size: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 800;
  }

  .btcContent1 h2 {
    font-size: 32px;
    margin-bottom: 0px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .btcContent1 p {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .launchBtn {
    font-size: 18px;
    padding: 15px 40px;
  }

  header.header .img {
    width: 30px;
    font-size: 30px;
  }

  .btcContent1 {
    height: 500px;
  }

  .dxoAnimatedBg {
    background-position: center;
  }

  .btcContent1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.launchBtn1.launchBtn {
  font-size: 18px !important;
  padding: 15px 40px !important;
  display: block !important;
  text-align: center;
}

@media (min-width: 1200px) {
  .launch .container {
    max-width: 1350px !important;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.launchBtn {
  background: linear-gradient(274.98deg, #CD3E3E -8.54%, #392355 109.61%);
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 800;
}

.dxoAnimatedBg {
  animation: image 5s infinite alternate;
  background-repeat: no-repeat;
  background-size: 100%;
}

@keyframes image {
  0% {
    background-image: url('./image/searchCoin.png');
  }

  100% {
    background-image: url('./image/searchCoinLight.png');
  }
}

.addressText {
  font-size: 11px;
}